/* Team Data Container */
.team-data {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Heading */
.team-data h2 {
  text-align: center;
  color: #333;
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Event Code Input */
.team-data .event-form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.team-data input {
  padding: 10px;
  width: 60%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.team-data button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.team-data button:hover {
  background-color: #0056b3;
}

/* Team Selection Dropdown */
.team-data .team-select-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.team-data select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: white;
}

/* Error Message */
.team-data .error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}

/* Table Container */
.table-container {
  margin-top: 30px;
  overflow-x: auto;
}

.team-data table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.team-data th, .team-data td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ccc;
}

.team-data th {
  background-color: #f1f1f1;
  cursor: pointer;
}

.team-data th:hover {
  background-color: #e0e0e0;
}

/* Table Row Hover Effect */
.team-data tr:hover {
  background-color: #f9f9f9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .team-data input {
    width: 80%;
  }

  .team-data button {
    width: 100%;
    margin-top: 10px;
  }

  .team-data select {
    width: 100%;
  }

  .team-data .table-container {
    padding: 15px;
  }

  .team-data th,
  .team-data td {
    font-size: 0.9rem;
  }
}
