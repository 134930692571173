/* Event Data Container */
.event-data {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Heading */
.event-data h2 {
  text-align: center;
  color: #333;
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Event Code Input */
.event-data .event-form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.event-data input {
  padding: 10px;
  width: 60%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.event-data button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.event-data button:hover {
  background-color: #0056b3;
}

/* Color-Coding Checkbox */
.event-data .color-code-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}

.event-data select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: white;
}

.event-data input[type="checkbox"] {
  margin-right: 10px;
}

.event-data .error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}

/* Table Container */
.table-container {
  margin-top: 30px;
  overflow-x: auto;
}

.event-data table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.event-data th, .event-data td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ccc;
}

.event-data th {
  background-color: #f1f1f1;
  cursor: pointer;
}

.event-data th:hover {
  background-color: #e0e0e0;
}

/* Table Row Hover Effect */
.event-data tr:hover {
  background-color: #f9f9f9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .event-data input {
    width: 80%;
  }

  .event-data button {
    width: 100%;
    margin-top: 10px;
  }

  .event-data select {
    width: 100%;
  }

  .event-data .table-container {
    padding: 15px;
  }

  .event-data th,
  .event-data td {
    font-size: 0.9rem;
  }
}
