/* General Styles */
.scouting-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.scouting-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Input and Textarea Fields */
input, textarea, select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box; /* Ensures padding is included in width */
}

input:focus, textarea:focus, select:focus {
  border-color: #007BFF;
  outline: none;
}

textarea {
  resize: vertical; /* Allows vertical resizing */
}

/* Dropdown (select) Styles */
select {
  -webkit-appearance: none; /* Removes default dropdown arrow for a cleaner look */
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3E%3Cpath fill="none" d="M4 5l4 4 4-4z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 16px;
}

select:focus {
  border-color: #007BFF;
}

/* Input buttons for increment/decrement */
.input-with-buttons {
  display: flex;
  align-items: center;
  width: 100%;
}

.input-with-buttons input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 60%; /* Input box takes 60% width */
}

.input-with-buttons button {
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 10px;
}

.input-with-buttons button:hover {
  background-color: #ddd;
}

.input-with-buttons button:focus {
  outline: none;
}

/* Submit Button */
.submit-button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007BFF;
  color: white;
  font-size: 16px;
  cursor: pointer;
  width: 100%; /* Make the submit button full width */
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .scouting-container {
    padding: 10px;
  }

  .scouting-form {
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 10px;
  }

  input, textarea, select, .submit-button {
    font-size: 14px;
  }

  .input-with-buttons input {
    font-size: 14px;
  }

  .input-with-buttons button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  /* Further adjustments for very small screens */
  .scouting-container {
    padding: 10px;
    margin: 10px;
  }

  .scouting-form {
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 8px;
  }

  .submit-button {
    font-size: 14px;
    padding: 8px;
  }
}
