.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}

.header {
  background-color: #ff6a00; /* Orange */
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  margin: 0;
}

.nav-menu {
  display: flex;
  gap: 15px;
}

.nav-menu a {
  color: white;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background 0.3s;
}

.nav-menu a:hover {
  background: rgba(255, 255, 255, 0.2); /* Lighten on hover */
}

.auth-buttons {
  display: flex;
  gap: 10px;
}

.main-content {
  padding: 20px;
  text-align: center;
}

@media (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .nav-menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-menu a {
    padding: 10px;
  }
}

.main-content {
  margin-left: 250px; /* Adjust based on sidebar width */
  padding: 20px;
  transition: margin-left 0.3s;
}

.sidebar.closed + .main-content {
  margin-left: 60px; /* Adjust for collapsed sidebar */
}


