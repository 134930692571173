/* Login.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f9f9f9;
}

.login-box {
  background: white;
  padding: 40px;
  max-width: 400px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.login-header {
  margin-bottom: 20px;
}

.login-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.login-description {
  font-size: 14px;
  color: #666;
}

.login-error {
  color: #e74c3c;
  font-size: 14px;
  margin-bottom: 15px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.login-input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}

.login-footer {
  margin-top: 20px;
}

.signup-button {
  background-color: #6c757d;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.signup-button:hover {
  background-color: #5a6268;
}

.footer-text {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

