/* Basic layout for the sidebar container */
.sidebar-container {
  display: flex;
  position: relative;
}

.sidebar-toggle {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  background-color: #ff6600; /* Orange color for button */
  color: white;
  border: none;
  border-radius: 5px;
}

.sidebar-toggle:hover {
  background-color: #cc5200; /* Darker orange on hover */
}

/* Sidebar Styles */
.sidebar {
  height: 100vh;
  width: 250px;
  position: fixed;
  top: 0;
  left: -250px; /* Initially hidden */
  background-color: #ff6600; /* Orange background */
  color: white;
  transition: left 0.3s ease;
  z-index: 2; /* Ensure it stays above the overlay */
}

.sidebar.open {
  left: 0; /* When open, move to visible position */
}

.nav-menu {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.nav-menu a {
  color: white;
  text-decoration: none;
  padding: 10px;
  margin: 5px 0;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.nav-menu a:hover {
  background-color: #cc5200; /* Darker orange on hover */
}

.nav-menu .auth-button {
  margin-top: 20px;
}

.nav-menu .auth-button button {
  padding: 10px;
  background-color: #cc5200; /* Darker orange for auth button */
  color: white;
  border: none;
  cursor: pointer;
}

.nav-menu .auth-button button:hover {
  background-color: #993d00; /* Even darker orange for hover */
}

/* Overlay to dim the background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1; /* Make sure the overlay is below the sidebar */
}

/* Optional: Make sure the sidebar is clickable even when overlay is present */
.overlay:not(.active) {
  display: none;
}
